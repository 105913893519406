@import './assets/style/variable.scss';

.app {
  max-width: 414px;
  margin: 0 auto;
  
  .ant-btn {
    width: 100%;
    height: 2.6rem;
    font-size: 1.1rem;
    border: none;
    border-radius: 0;
    letter-spacing: 0.05rem;
    &.ant-btn-primary {
      background: linear-gradient(to right, $danger, $warning);
    }

    &:hover {
      filter: brightness(1.05);
    }
    &:active {
      filter: brightness(0.95);
    }
  }

  .ant-input {
    background: $light;
    border: none;
    color: $danger;
    font-size: 1rem;
    border-radius: 0;
    &.ant-input-lg {
      height: 2.6rem;
    }
  }

  header {
    .img-box {
      width: 125%;
      z-index: 1;
      .text {
        position: absolute;
        bottom: 2rem;
        left: 50%;
        transform: translate(-50%, 0);
        h1 {
          background: linear-gradient(to bottom, $white 20%, #FFE569);
          background-clip: text;
          color: transparent;
        }
      }
    }
    .content {
      margin-top: -4rem;
      padding-top: 4rem;
    }
  }

  .title {
    position: absolute;
    top: -0.5rem;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 0 5rem;
  }

  .earning-wrapper {
    .earning-content {
      padding-top: 5rem;
      border-radius: 0.6rem 0.6rem 2rem 2rem;
      .img3-box {
        top: 1.8rem;
        .get-btn {
          height: 4.6rem;
          position: absolute;
          top: 50%;
          right: 0.2rem;
          transform: translate(0, -45%);
          &:hover {
            filter: brightness(1.05);
          }
          &:active {
            filter: brightness(0.95);
          }
        }
      }
    }
  }

  .team-wrapper {
    .team-content {
      padding-top: 2rem;
    }
  }

  .icon-wrapper {
    img {
      width: 2.4rem;
    }
  }
}